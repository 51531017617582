import * as React from "react";

import styled from "styled-components";
import { Helmet } from "react-helmet";

// sections
import SustainabilityLanding from "../components/sustainability-page";

const Root = styled.main`
  padding: 0px;
  width: 100vw;
  background: white;
  margin-top: 65px;

  @media (max-width: 600px) {
    margin-top: 57px;
  }

  @media (max-height: 500px) {
    margin-top: 49px;
  }
`;

// markup
const SustainabilityPage = () => {
  return (
    <Root>
      <SustainabilityLanding />
    </Root>
  );
};

export function Head() {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <title>Sustainability - UtterBerry</title>
      <meta
        name='description'
        content='Sustainability - UtterBerry is a provider of Artificially Intelligent wireless sensor systems for the development of Smart Cities and the revolutionisation of sensing capabilities.'
      ></meta>
    </Helmet>
  );
}

export default SustainabilityPage;
