import React, { useState, useCallback } from "react";

import { styled } from "@mui/material/styles";

import {
  Typography,
  Modal,
  ButtonBase,
  Box,
  useTheme,
  useMediaQuery,
  Button,
  IconButton,
} from "@mui/material";
import {
  DownloadOutlined,
  ArrowBack,
  ArrowForward,
  Close,
} from "@mui/icons-material";


import { StaticImage } from "gatsby-plugin-image";

// pdf viewer
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import UtterBerryLogo from "../../images/utterberry-title-white.webp";
import TitleBanner from "../../images/about-images/title-banner-1.svg";

import UtterBerryGreenImage from "../../images/sustainability-images/utterberry-green.webp";

import SustainabilityDoc from "../../documents/CRP_UtterBerry2.pdf";

const PREFIX = "SustainabilityLanding";

const classes = {
  root: `${PREFIX}-root`,
  titleContainer: `${PREFIX}-titleContainer`,
  whiteTitle: `${PREFIX}-whiteTitle`,
  landingImage: `${PREFIX}-landingImage`,
  greenLandingImage: `${PREFIX}-greenLandingImage`,
  landingStroke: `${PREFIX}-landingStroke`,
  titleText: `${PREFIX}-titleText`,
  content: `${PREFIX}-content`,
  image: `${PREFIX}-image`,
  mainImage: `${PREFIX}-mainImage`,
  mainContainer: `${PREFIX}-mainContainer`,
  innerMainContainer: `${PREFIX}-innerMainContainer`,
  rsContainer: `${PREFIX}-rsContainer`,
  suppliersContainer: `${PREFIX}-suppliersContainer`,
  dataContainer: `${PREFIX}-dataContainer`,
  cleanContainer: `${PREFIX}-cleanContainer`,
  imageText: `${PREFIX}-imageText`,
  london: `${PREFIX}-london`,
  leeds: `${PREFIX}-leeds`,
  imageContainerFill: `${PREFIX}-imageContainerFill`,
  imageFill: `${PREFIX}-imageFill`,
  uk: `${PREFIX}-uk`,
  utterberry: `${PREFIX}-utterberry`,
  data: `${PREFIX}-data`,
  suppliers: `${PREFIX}-suppliers`,
};

const Root = styled("div")(({ theme }) => ({
  width: "100vw",
  background: "white",
  position: "relative",

  [`& .${classes.titleContainer}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },

  [`& .${classes.whiteTitle}`]: {
    color: "#fff",
    paddingBottom: 5,
    fontWeight: "600",
    fontSize: 53,
    whiteSpace: "nowrap",
    [theme.breakpoints.up("lg")]: {
      fontSize: 68,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 38,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
    },
  },

  [`& .${classes.landingImage}`]: {
    position: "relative",
    zIndex: 1,
    marginLeft: "4px",
    height: "75px",
    marginBottom: 15,
    [theme.breakpoints.up("lg")]: {
      height: "100px",
    },
    [theme.breakpoints.down("md")]: {
      height: "55px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "34px",
      marginLeft: "2px",
      marginBottom: 9,
    },
  },

  [`& .${classes.greenLandingImage}`]: {
    position: "relative",
    zIndex: 1,
    marginLeft: "20px",
    height: "80px",
    [theme.breakpoints.up("lg")]: {
      height: "110px",
    },
    [theme.breakpoints.down("md")]: {
      height: "55px",
      marginBottom: "5px",
      marginLeft: "13px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "34px",
      marginLeft: "10px",
      marginBottom: "3px",
    },
  },

  [`& .${classes.landingStroke}`]: {
    width: "100%",
    position: "relative",
    top: 0,
    left: 0,
    zIndex: 0,
    objectFit: "fill",
    [theme.breakpoints.down("sm")]: {
      minHeight: "150px",
      width: "600px",
    },
  },

  [`& .${classes.titleText}`]: {
    color: "#540096",
    textShadow:
      "-1px -1px 0 rgba(231,197,246,0.7), 1px -1px 0 rgba(231,197,246,0.7), -1px 1px 0 rgba(231,197,246,0.7), 1px 1px 0 rgba(231,197,246,0.7)",
  },

  [`& .${classes.content}`]: {
    display: "grid",
    gridTemplateAreas: `"l c le"
                        "l c le"
                        "rs . us"
                        "rs . us"
                        "ss . su"
                        "ss . su"
                        "dp . d"
                        "dp . d"
                        "uk . cl"
                        "uk . cl"`,
    padding: "0px 30px 30px",
    width: "100%",
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas: `"c c"
                            "l le"
                            "rs rs"
                            "su su"
                            "dp dp"
                            "cl cl"`,
    },
  },

  [`& .${classes.image}`]: {
    width: "100%",
    // border: "3px solid rgba(89,168,86,1)",
    borderRadius: "10px",
    // borderStyle: "dashed",
  },

  [`& .${classes.mainImage}`]: {
    width: "100%",
  },

  [`& .${classes.mainContainer}`]: {
    gridArea: "c",
    position: "sticky",
    top: 80,
    alignSelf: "start",
    paddingTop: "0px",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      paddingTop: "0px",
      top: 0,
    },
  },

  [`& .${classes.innerMainContainer}`]: {
    border: "3px solid rgba(89,168,86,1)",
    padding: "5px",
    borderRadius: "10px",
    borderStyle: "dashed",
  },

  [`& .${classes.rsContainer}`]: {
    gridArea: "rs",
  },

  [`& .${classes.suppliersContainer}`]: {
    gridArea: "su",
  },

  [`& .${classes.dataContainer}`]: {
    gridArea: "dp",
  },

  [`& .${classes.cleanContainer}`]: {
    gridArea: "cl",
  },

  [`& .${classes.imageText}`]: {
    textAlign: "center",
    lineHeight: "20px",
    marginTop: 5,
    [theme.breakpoints.up("md")]: {
      lineHeight: "24px",
    },
  },

  [`& .${classes.london}`]: {
    gridArea: "l",
    flexDirection: "column",
  },

  [`& .${classes.leeds}`]: {
    gridArea: "le",
    flexDirection: "column",
  },

  [`& .${classes.imageContainerFill}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.imageFill}`]: {
    width: "100%",
  },

  [`& .${classes.uk}`]: {
    gridArea: "uk",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.utterberry}`]: {
    gridArea: "us",
    padding: "30px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.data}`]: {
    gridArea: "d",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.suppliers}`]: {
    gridArea: "ss",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  [`& .${classes.modalCard}`]: {
    background: "white",
    borderRadius: "4px",
    padding: "15px",
    boxShadow: "0 30px 40px 0 rgb(16 36 94 / 20%)",
  },

  [`& .${classes.topBanner}`]: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px 10px",
  },
  
  [`& .${classes.pdfButtons}`]: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    alignItems: "center",
    padding: "6px",
    width: "100%",
  },




}));


const ModalCard = styled(Box)(({ theme }) => ({
  background: "white",
  borderRadius: "4px",
  padding: "15px",
  boxShadow: "0 30px 40px 0 rgb(16 36 94 / 20%)",

  [`& .${classes.topBanner}`]: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px 10px",
  },

  [`& .${classes.pdfButtons}`]: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    alignItems: "center",
    padding: "6px",
    width: "100%",
  },
}));

const SustainabilityDocButton = ({ document }) => {
  const [openDoc, setOpenDoc] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const handleClose = useCallback(() => setOpenDoc(false), []);

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setLoaded(true);
    setNumPages(numPages);
  }, []);

  //const gImage = getImage(image);

  // media breaks for PDF size
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const medium = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <div>
      <ButtonBase
        onClick={() => setOpenDoc(true)}
      >
        <div>
          <StaticImage
            src={"../../images/sustainability-images/utterberry.webp"}
            className={classes.imageFill}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"Green UtterBerry"}
            width={330}
            height={330}
          />
          <Typography className={classes.imageText}>
            At UtterBerry we are committed to green and sustainable action in
            everything we do.
          </Typography>
        </div>
      </ButtonBase>
      {!document && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
          }}
          className={"backdrop-blur-careers"}
        ></div>
      )}
      {document && (
        <Modal
          open={openDoc}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            overflow: "scroll",
            height: "100%",
            padding: "20px",
          }}
        >
          <ModalCard>
            <Box className={classes.topBanner}>
              <IconButton
                color='primary'
                component='span'
                onClick={handleClose}
              >
                <Close />
              </IconButton>
              <Box
                className={classes.pdfButtons}
                style={{
                  display: loaded ? "" : "none",
                }}
              >
                <Button
                  startIcon={<DownloadOutlined />}
                  variant='contained'
                  style={{
                    marginRight: "10px",
                  }}
                  href={document}
                  target={"_blank"}
                >
                  Download
                </Button>
                <IconButton
                  color='primary'
                  component='span'
                  onClick={() => {
                    if (pageNumber > 1) {
                      setPageNumber(pageNumber - 1);
                    }
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <span>
                  {pageNumber} / {numPages}
                </span>
                <IconButton
                  color='primary'
                  component='span'
                  onClick={() => {
                    if (pageNumber < numPages) {
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                >
                  <ArrowForward />
                </IconButton>
              </Box>
            </Box>
            <Document file={document} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                pageNumber={pageNumber}
                scale={small ? 0.48 : medium ? 0.65 : 1}
              />
            </Document>
          </ModalCard>
        </Modal>
      )}
    </div>
  );
};

const SustainabilityLanding = () => {
  return (
    <Root>
      <div
        style={{
          width: "100%",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={TitleBanner}
          className={classes.landingStroke}
          alt={"Title Banner"}
        />
        <div
          style={{
            zIndex: 2,
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -70%)",
          }}
        >
          <span className={classes.titleContainer}>
            <Typography variant={"h2"} className={classes.whiteTitle}>
              Sustainability at
            </Typography>
            <img
              src={UtterBerryGreenImage}
              className={classes.greenLandingImage}
              alt={""}
            />
            <img
              src={UtterBerryLogo}
              className={classes.landingImage}
              alt={"UtterBerry"}
            />
          </span>
        </div>
      </div>
      <div className={classes.content}>
        <div className={`${classes.london} ${classes.imageContainerFill}`}>
          <StaticImage
            src={"../../images/sustainability-images/london-working.webp"}
            className={classes.imageFill}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"London Working"}
            width={400}
            height={280}
          />
          <Typography variant={"caption"}>In</Typography>
          <Typography className={classes.titleText} variant={"h4"}>
            London
          </Typography>
        </div>
        <div className={classes.mainContainer}>
          <div className={classes.innerMainContainer}>
            <SustainabilityDocButton document={SustainabilityDoc}>

            </SustainabilityDocButton>
            {/* <StaticImage
              src={"../../images/sustainability-images/utterberry.webp"}
              className={classes.imageFill}
              objectFit={"contain"}
              placeholder={"blurred"}
              alt={"Green UtterBerry"}
              width={330}
              height={330}
            />
            <Typography className={classes.imageText}>
              At UtterBerry we are committed to green and sustainable action in
              everything we do.
            </Typography> */}
          </div>
        </div>
        <div className={`${classes.leeds} ${classes.imageContainerFill}`}>
          <StaticImage
            src={"../../images/sustainability-images/leeds-working.webp"}
            className={classes.imageFill}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"Leeds Working"}
            width={400}
            height={280}
          />
          <Typography variant={"caption"}>And in</Typography>
          <Typography className={classes.titleText} variant={"h4"}>
            Leeds
          </Typography>
        </div>
        <div className={classes.rsContainer}>
          <StaticImage
            src={"../../images/sustainability-images/threers.webp"}
            className={classes.image}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"Recycle, Reuse, Repurpose"}
            width={350}
            height={240}
          />
          <Typography className={classes.imageText}>
            Internally we make sure to minimise all waste, whether that be
            recycling, reusing, or repurposing.
          </Typography>
        </div>
        <div className={`${classes.utterberry} ${classes.imageContainerFill}`}>
          <StaticImage
            src={"../../images/sustainability-images/utterberry-stroke.jpg"}
            className={classes.imageFill}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"UtterBerry Sensor"}
            width={300}
            height={195}
          />
        </div>
        <div className={`${classes.suppliers} ${classes.imageContainerFill}`}>
          <StaticImage
            src={"../../images/sustainability-images/security.webp"}
            className={classes.imageFill}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"Green Security"}
            width={360}
            height={250}
          />
        </div>
        <div className={classes.suppliersContainer}>
          <StaticImage
            src={"../../images/sustainability-images/suppliers.webp"}
            className={classes.image}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"Green Suppliers"}
            width={350}
            height={240}
          />
          <Typography className={classes.imageText}>
            Externally, we make certain that all of our suppliers are not only
            sustainable, but also ethical, as it is important to us as a company
            to maintain our sustainable and ethical practice in every aspect of
            our business.
          </Typography>
        </div>
        <div className={classes.dataContainer}>
          <StaticImage
            src={"../../images/sustainability-images/data.webp"}
            className={classes.image}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"Green Data"}
            width={350}
            height={240}
          />
          <Typography className={classes.imageText}>
            Importantly we also focus on the impact of our data processing, only
            saving data that is essential and relevant or if a client requests.
            As a result we maximise our reduction in CO2 emissions within our
            data retention system.
          </Typography>
        </div>
        <div className={`${classes.data} ${classes.imageContainerFill}`}>
          <StaticImage
            src={"../../images/sustainability-images/data-security.jpg"}
            className={classes.imageFill}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"Data Security"}
            width={350}
            height={245}
          />
        </div>
        <div className={`${classes.uk} ${classes.imageContainerFill}`}>
          <StaticImage
            src={"../../images/sustainability-images/united-kingdom.webp"}
            className={classes.imageFill}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"A Green UK"}
            width={360}
            height={420}
          />
        </div>
        <div className={classes.cleanContainer}>
          <StaticImage
            src={"../../images/sustainability-images/clean.webp"}
            className={classes.image}
            objectFit={"contain"}
            placeholder={"blurred"}
            alt={"Clean Energy & Manufacturing"}
            width={350}
            height={240}
          />
          <Typography className={classes.imageText}>
            Our new manufacturing hub in Leeds is currently under development,
            and we are taking this opportunity to research and invest in new,
            clean, green energy sources to power this new venture.
          </Typography>
        </div>
      </div>
    </Root>
  );
};

export default SustainabilityLanding;
